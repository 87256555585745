// Vars from Webpack config
$hasRtlSupport: false;
$enablePreloadFontFamily: true;
$doIncludeUtilClasses: false;
$doIncludeUtilBrandingClasses: false;
$doIncludeSearchBranding: false;
$hasDigitalObjects: true;
$enableAccordionStyleBordered: false;
$enableAccordionAlignmentCenter: false;
$enableAccordionStyleArrows: true;
$enableAccordionBehaviorOnlyOnSpecificViewPort: true;
$enableIssueItemCheckboxes: false;
$enableAlertMessages: false;
$enableTableStyleStacked: false;
$enableTableStyleBordered: true;
$enableTableExpanding: true;
$enableTableStyleStriped: false;
$enableTableCheckboxes: true;
$enableTableActions: false;
$enableTabTypeFlex: true;
$enableTabTypeVertical: false;
$enableTabStyleBackground: false;
$enableTabSwipeArrows: true;
$enableQuicksearchAutoComplete: true;
$enableQuicksearchAsMenu: true;
$enableQuicksearchCitationOption: true;
$enableSlideshowControls: true;
$enableSlideshowArrows: false;
$enableBinderListShare: true;
$enableBinderListShareInvitation: true;
$enableInstitutionBannerGraphic: true;
$enableListOfIssuesStyleCollapsible: false;
$enableListOfIssuesStyleWithinCoolbar: false;
$enablePairingManagementDefaultFilter: true;
$enablePairingManagementDefaultActions: true;
$enableSupplementalLinksUpdate: true;
$enablePublicationContentCorrections: true;
$enablePublicationContentEpubSection: false;
$enablePublicationListStyleSlideshow: false;
$enableLoginWithConnect: false;
$enableResponsiveMenu: false;
$confSearchTocWithCheckboxs: false;
$enableDefaultShowMoreStyles: true;
$enableUiTooltips: true;
$enableDoBundle: true;
$enableBackwardCompatibilityVariables: false;
$hasMonserratFont: true;
$hasJournalBranding: true;
$hasSiteBranding: false;
$hasAnimationLoginAndCartHover: true;
$hasMenuAnimationBorderBottom: true;
$hasAnimationArticleTools: true;
$hasDefaultLeftSections: true;
$hasAudioPlayer: false;
$hasPodcasts: false;
$hasAlphanumeric: true;
$hasPublicationMenu: false;
$hasSocialLinkTabletStyle: true;
$hasSectionsNavigationSeparator: true;
$hasFooterBg: true;
$hasSliderBg: true;
$hasJournalBannerBg: true;
$hasSearchBannerBg: true;
$hasArticleBannerBg: true;
$hasProductsListBannerBg: true;
$hasQuickSearchBar: true;
$hasAdvancedSearchLink: true;
$hasQuickSearchWithCitationLink: true;
$hasListButtonsStyle: false;
$hasIssueItemImg: false;
$hasIssueItemAuthor: false;
$hasSearchResultTabs: true;
$hasBrowsePublications: true;
$hasSearchSuffixDoubleQuotes: true;
$hasJcfRange: true;
$hasJcfCheckbox: true;
$hasJcfFile: true;
$hasMainSlider: true;
$hasNumericPaginationSlider: false;
$hasResourcesSlider: true;
$hasJournalsSlider: true;
$hasDropdownFadeLeftAnimation: true;
$hasShiftUpContentAnimation: false;
$hasTopBannerTitleZoomAnimation: false;
$hasMoveContentRightAnimation: false;
$hasPhotoAnimationEffectsClasses: false;
$hasSessionActivity: false;
$hasUsernameDefaultStyle: true;
$hasUsefulLinksHoverAnimation: true;
$hasShareLinksHoverAnimation: true;
$hasCircularProfileTabs: false;
$hasDefaultThemeHeader: true;
$hasHeaderDisplay: true;
$hasHeaderColumnsAndRows: true;
$hasQuickSearchAndCartAndLogin: true;
$hasDefaultThemeFooter: true;
$has-rtl-support: false;
$enable-preload-font-family: true;
$do-include-util-classes: false;
$do-include-util-branding-classes: false;
$do-include-search-branding: false;
$has-digital-objects: true;
$enable-accordion-style-bordered: false;
$enable-accordion-alignment-center: false;
$enable-accordion-style-arrows: true;
$enable-accordion-behavior-only-on-specific-view-port: true;
$enable-issue-item-checkboxes: false;
$enable-alert-messages: false;
$enable-table-style-stacked: false;
$enable-table-style-bordered: true;
$enable-table-expanding: true;
$enable-table-style-striped: false;
$enable-table-checkboxes: true;
$enable-table-actions: false;
$enable-tab-type-flex: true;
$enable-tab-type-vertical: false;
$enable-tab-style-background: false;
$enable-tab-swipe-arrows: true;
$enable-quicksearch-auto-complete: true;
$enable-quicksearch-as-menu: true;
$enable-quicksearch-citation-option: true;
$enable-slideshow-controls: true;
$enable-slideshow-arrows: false;
$enable-binder-list-share: true;
$enable-binder-list-share-invitation: true;
$enable-institution-banner-graphic: true;
$enable-list-of-issues-style-collapsible: false;
$enable-list-of-issues-style-within-coolbar: false;
$enable-pairing-management-default-filter: true;
$enable-pairing-management-default-actions: true;
$enable-supplemental-links-update: true;
$enable-publication-content-corrections: true;
$enable-publication-content-epub-section: false;
$enable-publication-list-style-slideshow: false;
$enable-login-with-connect: false;
$enable-responsive-menu: false;
$conf-search-toc-with-checkboxs: false;
$enable-default-show-more-styles: true;
$enable-ui-tooltips: true;
$enable-do-bundle: true;
$enable-backward-compatibility-variables: false;
$has-monserrat-font: true;
$has-journal-branding: true;
$has-site-branding: false;
$has-animation-login-and-cart-hover: true;
$has-menu-animation-border-bottom: true;
$has-animation-article-tools: true;
$has-default-left-sections: true;
$has-audio-player: false;
$has-podcasts: false;
$has-alphanumeric: true;
$has-publication-menu: false;
$has-social-link-tablet-style: true;
$has-sections-navigation-separator: true;
$has-footer-bg: true;
$has-slider-bg: true;
$has-journal-banner-bg: true;
$has-search-banner-bg: true;
$has-article-banner-bg: true;
$has-products-list-banner-bg: true;
$has-quick-search-bar: true;
$has-advanced-search-link: true;
$has-quick-search-with-citation-link: true;
$has-list-buttons-style: false;
$has-issue-item-img: false;
$has-issue-item-author: false;
$has-search-result-tabs: true;
$has-browse-publications: true;
$has-search-suffix-double-quotes: true;
$has-jcf-range: true;
$has-jcf-checkbox: true;
$has-jcf-file: true;
$has-main-slider: true;
$has-numeric-pagination-slider: false;
$has-resources-slider: true;
$has-journals-slider: true;
$has-dropdown-fade-left-animation: true;
$has-shift-up-content-animation: false;
$has-top-banner-title-zoom-animation: false;
$has-move-content-right-animation: false;
$has-photo-animation-effects-classes: false;
$has-session-activity: false;
$has-username-default-style: true;
$has-useful-links-hover-animation: true;
$has-share-links-hover-animation: true;
$has-circular-profile-tabs: false;
$has-default-theme-header: true;
$has-header-display: true;
$has-header-columns-and-rows: true;
$has-quick-search-and-cart-and-login: true;
$has-default-theme-footer: true;

@import '~ui-theme/scss/print';

.cme-exam-activity {
    &__items {
        &__prefix {
            .text-center {
                margin-right: 0;
            }
        }
    }
}
